.error-root {
  min-height: 471.6px;
  padding-top: 150px;
}

.error-layout {
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translate(-50%, -20%);
  -moz-transform: translate(-50%, -20%);
  -ms-transform: translate(-50%, -20%);
  -o-transform: translate(-50%, -20%);
  transform: translate(-50%, -20%);
  width: 100%;
  max-width: 440px;
  background: transparent;
}

.error-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 73px;
}

.error-icon path {
  fill: #fff;
}

.error-header {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: 73px;
  background: transparent;
}

.error-content {
  color: white;
  width: 100%;
  padding: 28px 20px 28px 20px;
  margin: 0 auto;
}

.error-content h1,
.error-content h2 {
  font-weight: 300;
  font-size: 32px;
  color: white;
  text-align: center;
  line-height: 36px;
}

.error-content-title {
  margin-top: 0.75em;
  margin-bottom: 0em;
}

.error-content-summary {
  margin-top: 1em;
}
