.application-layout-root {
  height: 100vh;
  padding: 0;
  margin: 0;
  background-image: url('../../assets/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.application-window-tint {
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.698039215686274);
}

.application-layout {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 440px;
  background: transparent;
  min-height: 640px;
}

.sd-header {
  position: relative;
  height: 120px;
  width: 100%;
  border-style: solid;
  border-width: 0px 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.sd-content {
  width: 100%;
  padding: 28px 20px 28px 20px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  .page-header {
    flex: 0 0 100px;
  }

  .page-content {
    flex: 1 1 auto;
  }
}

.app-logo {
  display: inline-block;
  position: relative;
  height: 30px;
}

.full-width-button {
  width: 100%;
}

.loading {
  .rotate {
    vertical-align: middle;
    svg {
      margin: 0;
      path {
        fill: #333333;
      }
    }
  }
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
