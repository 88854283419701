.disclaimer-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.disclaimer-button {
  margin: 140px 0 0 0;
  width: 100%;
  text-transform: uppercase;
}

.info-icon {
  cursor: pointer;
}
