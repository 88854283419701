.login-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .login-company-input {
    margin: 17px 0 22px 0;
    height: 40px;
    font-weight: 400;
    font-size: 18px;
  }

  .login-input {
    margin: 8px 0 0 0;
    height: 40px;
    font-weight: 400;
    font-size: 18px;
  }

  .login-link-forgot-password {
    float: right;
  }

  .login-password-remember-me-row {
    margin: 15px 0 0 0;
  }

  .login-button {
    margin: 65px 0 0 0;
    width: 100%;
    text-transform: uppercase;
  }
}

.no-form {
  height: 255px;
}

.error-text {
  font-family: 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 16px;
}

.info-icon {
  cursor: pointer;
}
